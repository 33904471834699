<template>
  <div id="bcompany">
    <div class="bcompany-info">
      <el-image :src="logo" fit="cover"></el-image>
      <span>
        <p>公司代码：{{ code }}</p>
        <p>{{ name }}</p>
        <p>
          <i>供应商公司</i>
          <router-link
            :to="{ path: '/control/companyb/info', query: { code: code } }"
            >详情</router-link
          >
        </p>
      </span>
      <div class="company-base-content-right">
        {{ { 1: "待审批", 2: "启用中", 3: "审批失败" }[this.status] }}
      </div>
    </div>
    <ul>
      <li>
        <p>累计销售额</p>
        <p>{{ cumulativeSales | currency }} 万元</p>
      </li>
      <li>
        <p>年销售额</p>
        <p>{{ yearSalse | currency }} 万元</p>
      </li>
      <li>
        <p>本月销售额</p>
        <p>{{ monthSalse | currency }} 万元</p>
      </li>
      <li>
        <p>总产品数量</p>
        <p>{{ sumProduct | currency }} 个</p>
      </li>
      <li>
        <p>待审核产品数量</p>
        <p>{{ auditProduct | currency }} 个</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "bcompany-index",
  data() {
    return {
      logo: "",
      code: "",
      name: "",
      type: "",
      cumulativeSales: "",
      yearSalse: "",
      monthSalse: "",
      sumProduct: "",
      auditProduct: "",
      status: "",
      typeList: {
        0: "集成类",
        1: "供货方",
        2: "招标方",
      },
    };
  },
  mounted() {
    this.getData();
    let user = JSON.parse(localStorage.user);
    if (user.type === 0) {
      this.$router.push("/a-company");
    } else if (user.type === 1) {
      //   this.$router.push("/control/companyb");
    } else if (user.type === 2) {
      this.$router.push("/control/companyc");
    }
  },
  methods: {
    getData() {
      this.$axios.get("/member/benterprisePage").then((res) => {
        if (res.state === 200) {
          const {
            logo,
            name,
            code,
            type,
            cumulativeSales,
            yearSalse,
            monthSalse,
            sumProduct,
            auditProduct,
          } = res.data;
          this.logo = logo;
          this.name = name;
          this.code = code;
          this.type = this.typeList[type];
          this.cumulativeSales = cumulativeSales ?? 0;
          this.yearSalse = yearSalse ?? 0;
          this.monthSalse = monthSalse ?? 0;
          this.sumProduct = sumProduct ?? 0;
          this.auditProduct = auditProduct ?? 0;
          this.$axios
            .get(`/member/selectMemberDetail/${this.code}`)
            .then((res) => {
              this.status = res.data.status;
            });
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#bcompany {
  display: flex;
  flex-direction: column;
  margin: 0 0 114px;
  .bcompany-info {
    width: 664px;
    height: 188px;
    border: 1px solid #ededed;
    margin: 0 0 24px;
    padding: 32px;
    display: flex;
    ::v-deep.el-image {
      width: 124px;
    }
    position: relative;
    .company-base-content-right {
      position: absolute;
      width: 100px;
      height: 50px;
      font-size: 30px;
      text-align: right;
      right: 30px;
      top: 30px;
    }
    span {
      flex: 1;
      line-height: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 0 0 0 32px;
      p {
        &:first-child {
          font-size: 20px;
          color: #333;
        }
        &:nth-child(2) {
          font-size: 28px;
          color: #333;
        }
        &:last-child {
          display: flex;
          justify-content: space-between;
          i {
            width: 104px;
            height: 32px;
            display: inline-block;
            line-height: 32px;
            text-align: center;
            font-size: 16px;
            color: #fff;
            background: #004375;
            font-style: normal;
          }
          a {
            font-size: 18px;
            color: #3883ff;
            text-decoration: none;
          }
        }
      }
    }
  }
  & > ul {
    height: 424px;
    border: 1px solid #ededed;
    padding: 20px 0 0 20px;
    li {
      width: 254px;
      height: 154px;
      border: 1px solid #ededed;
      list-style: none;
      margin: 12px;
      float: left;
      color: #333;
      padding: 52px 0 0;
      text-align: center;
      p {
        line-height: 1;
        &:first-child {
          font-size: 20px;
        }
        &:last-child {
          font-size: 30px;
          margin: 20px 0 0;
        }
      }
    }
  }
}
</style>
